fieldset {
  border: none;
  padding: 0;
  margin: 0;
}
fieldset label {
  display: block;
  margin: 1rem 0 0.25rem;
  font-size: 18px;
}
fieldset label.error {
  color: rgb(194, 0, 47);
}
a {
  text-decoration: none;
}
@font-face {
  font-family: 'Lato-Regular';
  src: url('./fonts/Lato/Lato-Regular.woff2') format('woff2'),
    url('./fonts/Lato/Lato-Regular.woff') format('woff'),
    url('./fonts/Lato/Lato-Regular.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato-Bold';
  src: url('./fonts/Lato/Lato-Bold.woff2') format('woff2'),
    url('./fonts/Lato/Lato-Bold.woff') format('woff'),
    url('./fonts/Lato/Lato-Bold.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NotoKufiArabic';
  src: url('./fonts/NotoKufiArabic/NotoKufiArabic-Regular.woff2')
      format('woff2'),
    url('./fonts/NotoKufiArabic/NotoKufiArabic-Regular.woff') format('woff'),
    url('./fonts/NotoKufiArabic/NotoKufiArabic-Regular.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NotoKufiArabic-Bold';
  src: url('./fonts/NotoKufiArabic/NotoKufiArabic-Bold.woff2') format('woff2'),
    url('./fonts/NotoKufiArabic/NotoKufiArabic-Bold.woff') format('woff'),
    url('./fonts/NotoKufiArabic/NotoKufiArabic-Bold.ttf') format('truetype');
  font-style: normal;
  font-display: swap;
}
